import TotalSupplyHistoryView from "../components/TotalSupplyHistoryView";
import TotalBalancesOverview from "../components/TotalBalancesOverview";

const OversightPage = () => {

  return (
    <div className="content-area">
      <h1>Escrow/Supply Balance Overview - Current</h1>
      <TotalBalancesOverview/>
      <h1>Escrow/Supply Balance Overview - History</h1>
      <TotalSupplyHistoryView/>
    </div>
  );
};

export default OversightPage;
