import StatusIndicator from "../components/StatusIndicator";
import ContractsTable from "../components/ContractsTable";
import { NetworkType } from "../components/NetworkType";

const InfoPage = () => {
  return (
    <div className="content-area">
      <div>
        <h2>Fabric Status</h2>
        {StatusIndicator("fabric", NetworkType.FABRIC)}
      </div>
      <div>
        <h2>Besu Status</h2>
        {StatusIndicator("besu", NetworkType.BESU)}
      </div>
      <div>
        <h2>Contracts in use</h2>
        {ContractsTable()}
      </div>
    </div>
  );
};

export default InfoPage;
