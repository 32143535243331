import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { accountMonitoringApi, oversightApi } from "../apiClients/middleware";
import { getPollingInterval } from "../components/Environment";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Balance } from "../generated-client";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import TotalSupplyHistoryView from "../components/TotalSupplyHistoryView";

dayjs.extend(utc);

const OversightPage = () => {
  const [totalSupply, setTotalSupply] = useState<number | null>(null);
  const [ownBalance, setOwnBalance] = useState<Balance | null>(null);

  const networkIdWnokStar = "besu";
  const networkIdWnok = "fabric";

  useEffect(() => {
    const fetchTotalSupply = async () => {
      try {
        const response = await oversightApi.getTotalSupply(networkIdWnokStar);
        setTotalSupply(response.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err);
        } else {
          console.log("An unexpected error type occurred");
        }
      }
    };
    fetchTotalSupply();

    const intervalId = setInterval(fetchTotalSupply, getPollingInterval()); // Fetch every polling interval

    return () => clearInterval(intervalId);
  }, [networkIdWnokStar]);

  useEffect(() => {
    const fetchOwnBalance = async () => {
      try {
        const response =
          await accountMonitoringApi.getEscrowAccountBalance(networkIdWnok);
        setOwnBalance(response.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err);
        } else {
          console.log("An unexpected error type occurred");
        }
      }
    };
    fetchOwnBalance();

    const intervalId = setInterval(fetchOwnBalance, getPollingInterval()); // Fetch every polling interval

    return () => clearInterval(intervalId);
  }, [networkIdWnok]);

  return (
    <div className="content-area">
      <h1>Escrow/supply balance - current</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>wNOK under Escrow</TableCell>
              <TableCell>Total wNOK* Supply</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{ownBalance?.amount}</TableCell>
              <TableCell>{totalSupply}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <BarChart
            dataset={[
              {
                wNOK: ownBalance?.amount,
                quantity: "wNOK under Escrow",
              },
              {
                wNOK: totalSupply,
                quantity: "Total wNOK* Supply",
              },
            ]}
            xAxis={[{ scaleType: "band", dataKey: "quantity" }]}
            series={[
              {
                dataKey: "wNOK",
                valueFormatter: (value) => value.toLocaleString(),
                color: "#f07d00",
              },
            ]}
            height={300}
            margin={{ left: 80 }}
          />
        </Box>
      </div>
      <h1>Escrow/supply balance - history</h1>
      {TotalSupplyHistoryView()}
    </div>
  );
};

export default OversightPage;
