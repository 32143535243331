export const keyCloakSecured =
  process.env.REACT_APP_KEY_CLOAK_SECURED === "yes";

export const getKeyCloakSecured = () => {
  return (
    (process.env.REACT_APP_KEY_CLOAK_SECURED || "").toUpperCase() === "YES"
  );
};

export const orgLogoPath = process.env.REACT_APP_ORG_LOGO_PATH;

export const bridgeAddress = process.env.REACT_APP_BRIDGE_ADDRESS;

export const port = process.env.REACT_APP_PORT;

export const getProxyURL = () => {
  return process.env.REACT_APP_PROXY_URL || "FAIL_MISSING_URL";
};

export const getPollingInterval = () => {
  return Number(process.env.REACT_APP_POLLING_INTERVAL_MS) || 3000; // Default to 3000ms
};
