import TotalBalancesTable from "./TotalBalancesTable";
import Box from "@mui/material/Box";
import {BarChart} from "@mui/x-charts/BarChart";
import {networkProperties} from "../utils/networkProperties";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {Balance} from "../generated-client";
import {accountMonitoringApi, oversightApi} from "../apiClient";
import {getPollingInterval} from "./Environment";

dayjs.extend(utc);

interface DatasetEntry {
    type: string;
    wNOK?: number;
    [key: string]: string | number | undefined;
}

export default function TotalBalancesOverview() {
    const dynamicDataset = (
        ownBalance: Balance | null,
        totalSupplies: Record<string, number | null>,
    ): DatasetEntry[] => {
        const baseDataset: DatasetEntry = {
            type: "Wholesale under escrow vs. External ledger supply",
            wNOK: ownBalance?.amount,
        };

        networkProperties.forEach(({ networkId }) => {
            baseDataset[networkId] = totalSupplies?.[networkId] || 0;
        });
        return [baseDataset];
    };

    const dynamicSeries = (
        networkProperties: { networkId: any; label: any; color: any }[],
    ) => {
        return [
            {
                dataKey: "wNOK",
                valueFormatter: (value: { toLocaleString: () => any }) =>
                    value?.toLocaleString(),
                stack: "wholesale",
                label: "Wholesale under Escrow",
                color: "#f07d00",
            },
            ...networkProperties.map(({ networkId, label, color }) => ({
                dataKey: networkId,
                valueFormatter: (value: { toLocaleString: () => any }) =>
                    value?.toLocaleString(),
                stack: "external",
                label: `Total ${label} Supply`,
                color: color,
            })),
        ];
    };
    const [totalSupplies, setTotalSupplies] = useState<
        Record<string, number | null>
    >({});
    const [ownBalance, setOwnBalance] = useState<Balance | null>(null);
    const networkIdWnok = "fabric";

    useEffect(() => {
        const fetchTotalSupplies = async () => {
            try {
                const supplies: Record<string, number> = {};
                for (const { networkId } of networkProperties) {
                    const response = await oversightApi.getTotalSupply(networkId);
                    supplies[networkId] = response.data;
                }
                setTotalSupplies(supplies);
            } catch (err: unknown) {
                if (err instanceof Error) {
                    console.log(err.message);
                } else {
                    console.log("An unexpected error type occurred");
                }
            }
        };
        fetchTotalSupplies();

        const intervalId = setInterval(fetchTotalSupplies, getPollingInterval());

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const fetchOwnBalance = async () => {
            try {
                const response =
                    await accountMonitoringApi.getEscrowAccountBalance(networkIdWnok);
                setOwnBalance(response.data);
            } catch (err: unknown) {
                if (err instanceof Error) {
                    console.log(err);
                } else {
                    console.log("An unexpected error type occurred");
                }
            }
        };
        fetchOwnBalance();

        const intervalId = setInterval(fetchOwnBalance, getPollingInterval()); // Fetch every polling interval

        return () => clearInterval(intervalId);
    }, [networkIdWnok]);

    return (
        <>
            <TotalBalancesTable escrowAmount={ownBalance?.amount} totalSupplies={totalSupplies}/>
            <div>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <BarChart
                        dataset={dynamicDataset(ownBalance, totalSupplies)}
                        xAxis={[{ scaleType: "band", dataKey: "type" }]}
                        series={dynamicSeries(networkProperties)}
                        height={300}
                        margin={{ left: 80 }}
                    />
                </Box>
            </div>
        </>
    )
}