import { LineChart } from "@mui/x-charts/LineChart";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import HistoryRangePicker from "./HistoryRangePicker";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { accountMonitoringApi, oversightApi } from "../apiClient";
import { Balance, BalanceList } from "../generated-client";
import UpdateIcon from "@mui/icons-material/Update";
import dayjs, { Dayjs } from "dayjs";
import { networkProperties } from "../utils/networkProperties";

interface SeriesData {
  data: number[];
  color: string;
  timestamps: Date[];
  label: string;
}

export default function TotalSupplyHistoryView() {
  const networkIdFabric = "fabric";
  const externalNetworkIdList = networkProperties.map(
    ({ networkId }) => networkId,
  );

  const [tFrom, setTFrom] = useState<Dayjs | null>(
    dayjs.utc().subtract(60, "minutes"),
  );
  const [tTo, setTTo] = useState<Dayjs | null>(dayjs.utc());

  const [tFromSelected, setTFromSelected] = useState<Dayjs | null>(null);
  const [tToSelected, setTToSelected] = useState<Dayjs | null>(null);

  const [totalSupplyHistory, setTotalSupplyHistory] = useState<{
    [key: string]: BalanceList;
  }>({});
  const [ownBalanceHistory, setOwnBalanceHistory] = useState<BalanceList>({
    list: [],
  });
  const [loadingTotalSupplyHistory, setLoadingTotalSupplyHistory] =
    useState(false);
  const [loadingOwnBalanceHistory, setLoadingOwnBalanceHistory] =
    useState(false);

  if (tFrom && tTo) {
    if (!tFromSelected && !tToSelected) {
      handleTimeRangeUpdateClick();
    }
  }

  const ownBalanceSeries: SeriesData = {
    ...processBalances(ownBalanceHistory.list || [], "#f07d00"),
    label: "Wholesale under Escrow",
  };

  const networkSeries: SeriesData[] = Object.keys(totalSupplyHistory)
      .map((networkId) => {
        const network = networkProperties.find((n) => n.networkId === networkId);
        if (network) {
          return {
            ...processBalances(
                totalSupplyHistory[networkId].list || [],
                network.color,
            ),
            label: `Total ${network.label || ""} Supply`,
          };
        } else {
          console.warn(`Network ID ${networkId} not found in networkProperties`);
          return null;
        }
      })
      .filter(Boolean) as SeriesData[];

  const seriesData: SeriesData[] = [ownBalanceSeries, ...networkSeries];
  const extendedSeriesData = tFromSelected && tToSelected
      ? applyExtensionToSeriesData(seriesData, tToSelected)
      : seriesData;
  const allTimestamps = mergeTimestamps(extendedSeriesData);

  // TODO: implement const to load supply/escrow at date when no history available
  const [totalSupplyAtDate, setTotalSupplyAtDate] = useState<Balance>({
    accountNo: "",
    amount: 0,
    timestamp: "",
  });
  const [loadingTotalSupplyAtDat, setLoadingTotalSupplyAtDate] =
      useState(false);
  const [ownBalanceAtDate, setOwnBalanceAtDate] = useState<Balance>({
    accountNo: "",
    amount: 0,
    timestamp: "",
  });
  const [loadingOwnBalanceAtDate, setLoadingOwnBalanceAtDate] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (
        tFromSelected &&
        tToSelected &&
        !loadingOwnBalanceHistory &&
        !loadingTotalSupplyHistory
      ) {
        setLoadingOwnBalanceHistory(true);
        setLoadingTotalSupplyHistory(true);
        const tFromString = tFromSelected.toISOString();
        const tToString = tToSelected.toISOString();
        try {
          const responseFabric =
            await accountMonitoringApi.getEscrowAccountBalanceHistory(
              networkIdFabric,
              tFromString,
              tToString,
            );
          let listFabric: BalanceList = { list: [] };
          if (responseFabric.data.list) {
            listFabric.list = responseFabric.data.list
              .sort((a, b) => a.timestamp!.localeCompare(b.timestamp!))
              .reverse();
            setOwnBalanceHistory(listFabric);
          }

          const responses: Record<string, BalanceList> = {};
          for (const networkId of externalNetworkIdList) {
            const responseBesu = await oversightApi.getTotalSupplyHistory(
              networkId,
              tFromString,
              tToString,
            );
            let listBesu: BalanceList = { list: [] };
            if (responseBesu.data.list) {
              listBesu.list = responseBesu.data.list
                .sort((a, b) => a.timestamp!.localeCompare(b.timestamp!))
                .reverse();
              responses[networkId] = listBesu;
            }
          }
          setTotalSupplyHistory(responses);
        } catch (error: unknown) {
          if (error instanceof Error) {
            console.error(error.message);
          } else {
            console.error("An unexpected error type occurred");
          }
        }
        setLoadingOwnBalanceHistory(false);
        setLoadingTotalSupplyHistory(false);
      }
    };
    fetch();
  }, [tFromSelected, tToSelected]);

  function handleTimeRangeUpdateClick() {
    setTFromSelected(tFrom);
    setTToSelected(tTo);
  }

  function processBalances(balances: Balance[], color: string) {
    if (!balances || balances.length === 0) {
      return {
        data: [],
        color,
        timestamps: [],
        label: "",
      };
    }

    const amounts = balances.map((balance) => balance.amount!);
    const times = balances.map((balance) =>
      dayjs(balance.timestamp).local().toDate(),
    );

    return {
      data: amounts,
      color,
      timestamps: times,
      label: "",
    };
  }

  // function to return all unique timestamps
  function mergeTimestamps(seriesData: SeriesData[]): Date[] {
    const timestampsOnly = seriesData.flatMap(seriesData => seriesData.timestamps);
    const uniqueTimestamps = timestampsOnly.filter(
        (timestamp, index, self) =>
            index === self.findIndex((t) => t.getTime() === timestamp.getTime())
    );
    return uniqueTimestamps.sort((a, b) => b.getTime() - a.getTime()).reverse();
  }

  function extendSeriesData(series: SeriesData, tTo: Dayjs): SeriesData {
    const { data, timestamps } = series;

    if (data.length > 0 && timestamps.length > 0) {
      const reversedTimestamps = timestamps.reverse();

      const extendedData = [...data, data[data.length - 1]].reverse();
      const extendedTimestamps = [
        ...reversedTimestamps,
        tTo.subtract(2, "second").toDate(),
      ];

      return {
        ...series,
        data: extendedData,
        timestamps: extendedTimestamps,
      };
    }
    // return unchanged if it is empty
    return series;
  }

  function applyExtensionToSeriesData(seriesDataArray: SeriesData[], tTo: Dayjs): SeriesData[] {
    return seriesDataArray.map(series => extendSeriesData(series, tTo));
  }

  return (
    <>
      {!tFromSelected || !tToSelected ? (
        <>Loading...</>
      ) : (
        <Box>
          <LineChart
            xAxis={[
              {
                data: allTimestamps,
                scaleType: "time",
                min: tFromSelected?.toDate()!,
                max: tToSelected?.toDate()!,
              },
            ]}
            series={extendedSeriesData.map((series) => ({
              ...series,
              curve: "stepAfter",
            }))}
            height={300}
            margin={{ left: 80 }}
          />
        </Box>
      )}
      <Box boxShadow={1} bgcolor="background.paper" borderRadius={1} p={2}>
        <Stack spacing={2} direction="row" width="95%" alignItems="center">
          {HistoryRangePicker(tFrom, setTFrom, tTo, setTTo)}
          <LoadingButton
            loading={loadingTotalSupplyHistory || loadingOwnBalanceHistory}
            variant="outlined"
            onClick={() => {
              handleTimeRangeUpdateClick();
            }}
          >
            <UpdateIcon />
          </LoadingButton>
        </Stack>
      </Box>
    </>
  );
}
