import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import UnifiedDashboardComponent from "../components/UnifiedDashboardComponent";
import { NetworkType } from "../components/NetworkType";

dayjs.extend(utc);

const BesuDashboardPage = () => {
  const networkId = "besu";
  return (
    <div className="content-area">
      {UnifiedDashboardComponent(networkId, NetworkType.BESU)}
    </div>
  );
};

export default BesuDashboardPage;
