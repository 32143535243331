import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";

import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  EntityNameMapping,
  TransactionHistoryTransactions,
} from "../generated-client";
import {
  formattedAmount,
  formattedOperation,
  getCounterparty,
} from "../services/TransactionTableFormat";
import {
  TransactionsWithUserFriendlyNames,
  transformAccountTransactions,
} from "../utils/helpers";

dayjs.extend(localizedFormat);

export default function BasicTable(
  transactions: Array<TransactionHistoryTransactions> | undefined,
  networkId: string,
  nameMapping: EntityNameMapping[],
) {
  if (!transactions) return <>Loading</>;
  const transformedTransactions = transformAccountTransactions(
    transactions,
    nameMapping,
    networkId,
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Operation</TableCell>
            <TableCell>Counterparty</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transformedTransactions == undefined ||
          transformedTransactions.length === 0 ? (
            <TableRow>
              {" "}
              No transactions found in the given time range.{" "}
            </TableRow>
          ) : (
            transformedTransactions!.map((transaction) => (
              <TableRow
                key={transaction.timestamp}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {dayjs(transaction.timestamp).local().format("L LT")}
                </TableCell>
                <TableCell>{formattedOperation(transaction)}</TableCell>
                <TableCell>{getCounterparty(transaction)}</TableCell>
                <TableCell>{formattedAmount(transaction)}</TableCell>
                <TableCell>{transaction.ownBalance}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
