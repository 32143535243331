import { NetworkType } from "./NetworkType";
import BalancesTable from "./BalancesTable";
import { AllParticipants } from "./AllParticipants";
import OwnAssetRefsTable from "./OwnAssetRefsTable";
import AccountHistoryView from "./AccountHistoryView";
import { useCallback, useState } from "react";
import { EntityNameMapping } from "../generated-client";
import { staticDataApi } from "../apiClients/middleware";

export default function UnifiedDashboardComponent(
  networkId: string,
  networkType: NetworkType,
) {
  const [nameMapping, setNameMapping] = useState<Array<EntityNameMapping>>([]);
  const [nameMappingHasBeenFetched, setNameMappingHasBeenFetched] =
    useState<Boolean>(false);

  const fetchNameMappings = useCallback(async () => {
    try {
      const response = await staticDataApi.getEntityNameMapping();
      setNameMapping(response.data);
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.log(err);
      } else {
        console.log("An unexpected error type occurred");
      }
    }
  }, []);

  if (!nameMappingHasBeenFetched) {
    fetchNameMappings();
    setNameMappingHasBeenFetched(true);
  }

  return (
    <div>
      <h1>Account</h1>
      {BalancesTable(networkId, nameMapping)}

      {networkType === NetworkType.FABRIC && (
        <>
          <h1>Network Participants</h1>
          {AllParticipants(networkId, nameMapping)}
        </>
      )}

      <h1>Asset References</h1>
      {OwnAssetRefsTable(networkId, nameMapping)}

      <h1>Account History</h1>
      {AccountHistoryView(networkId, nameMapping)}
    </div>
  );
}
