import {
  Configuration,
  AccountMonitoringApi,
  BridgingApi,
  NetworkInfoApi,
  InfoApi,
  OversightApi,
  StaticDataApi,
} from "../generated-client";
import { getKeyCloakSecured, getProxyURL } from "../components/Environment";
import { GetAccessToken } from "../services/Keycloak";

const getAccessTokenWrapper = async (
  name?: string,
  scopes?: string[],
): Promise<string> => {
  console.log(
    "The name variable used to match the pattern requested by the Config class:",
    name,
  );
  console.log(
    "The scope variable used to match the pattern requested by the Config class:",
    scopes,
  );
  let token = await GetAccessToken();
  if (token == null) {
    // This covers both null and undefined
    return "FAIL_failed_to_retrieve_access_token_from_keycloak";
  }
  return token;
};

const config = new Configuration({
  basePath: getProxyURL(),
});

if (getKeyCloakSecured()) {
  config.accessToken = getAccessTokenWrapper;
}

// API instances
const accountMonitoringApi = new AccountMonitoringApi(config);
const bridgingApi = new BridgingApi(config);
const networkInfoApi = new NetworkInfoApi(config);

const oversightApi = new OversightApi(config);

const infoApi = new InfoApi(config);

const staticDataApi = new StaticDataApi(config);

// Export the API instances
export {
  accountMonitoringApi,
  bridgingApi,
  networkInfoApi,
  infoApi,
  oversightApi,
  staticDataApi,
};
