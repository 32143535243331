import {
  AssetRef,
  Balance,
  EntityNameMapping,
  TransactionHistoryTransactions,
} from "../generated-client";
import dayjs, { Dayjs } from "dayjs";
import { networkProperties } from "./networkProperties";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export interface AssetRefWithUserFriendlyNames extends AssetRef {
  ownerUserFriendlyName?: string;
  remoteRecipientUserFriendlyName?: string;
}

export interface TransactionsWithUserFriendlyNames
  extends TransactionHistoryTransactions {
  accountNoFromUserFriendlyName?: string;
  accountNoToUserFriendlyName?: string;
  operationUserFriendlyName?: string;
}

function getEntityNameMap(entityNameMapping: EntityNameMapping[]): {
  [key: string]: { [key: string]: string };
} {
  const entityNameMap: { [key: string]: { [key: string]: string } } = {};

  const entityNameMapFabric: { [key: string]: string } = {};
  entityNameMapping.forEach((entityName) => {
    entityNameMapFabric[entityName.utxoName.toLowerCase()] =
      entityName.legalName;
  });
  entityNameMap["fabric"] = entityNameMapFabric;

  networkProperties.forEach(({ networkId }) => {
    const entityNameMapBesu: { [key: string]: string } = {};
    entityNameMapping.forEach(
      (entityName: { besuAddress: string; legalName: any }) => {
        entityNameMapBesu[entityName.besuAddress.toLowerCase()] =
          entityName.legalName;
      },
    );
    entityNameMapBesu["0x0000000000000000000000000000000000000000"] = "--";
    entityNameMap[networkId] = entityNameMapBesu;
  });
  return entityNameMap;
}

export function transformAccountBalance(
  balance: Balance,
  entityNameMapping: EntityNameMapping[] | undefined,
  networkId: string,
): Balance | undefined {
  if (!balance.accountNo || !entityNameMapping) return balance;

  const entityNameMap = getEntityNameMap(entityNameMapping);

  return {
    ...balance,
    accountNo:
      entityNameMap[networkId][balance.accountNo.toLowerCase()] ||
      balance.accountNo,
  };
}

export function transformAccountNames(
  accountNames: string[] | undefined,
  entityNameMapping: EntityNameMapping[] | undefined,
  networkId: string,
): string[] | undefined {
  if (!accountNames || !entityNameMapping) return accountNames;

  const entityNameMap = getEntityNameMap(entityNameMapping);

  return accountNames.map(
    (accountName) =>
      entityNameMap[networkId][accountName.toLowerCase()] || accountName,
  );
}

export function transformAssetRefs(
  assetRefs: AssetRef[] | undefined,
  entityNameMapping: EntityNameMapping[] | undefined,
  networkId: string,
): AssetRefWithUserFriendlyNames[] | undefined {
  if (!assetRefs || !entityNameMapping) return assetRefs;

  const entityNameMap = getEntityNameMap(entityNameMapping);

  return assetRefs.map((assetRef) => ({
    ...assetRef,
    ownerUserFriendlyName:
      assetRef.owner == undefined
        ? assetRef.owner
        : entityNameMap[networkId][assetRef.owner.toLowerCase()] ||
          assetRef.owner,
    remoteRecipientUserFriendlyName:
      assetRef.remoteNetwork == undefined ||
      assetRef.remoteRecipient == undefined
        ? assetRef.remoteRecipient
        : entityNameMap[assetRef.remoteNetwork][
            assetRef.remoteRecipient.toLowerCase()
          ] || assetRef.remoteRecipient,
  }));
}

export function transformAccountTransactions(
  transactions: TransactionHistoryTransactions[] | undefined,
  entityNameMapping: EntityNameMapping[] | undefined,
  networkId: string,
): TransactionsWithUserFriendlyNames[] | undefined {
  if (!transactions || !entityNameMapping) return transactions;

  const entityNameMap = getEntityNameMap(entityNameMapping);

  const operationMap: { [key: string]: string } = {
    TransferwNOK: "Transfer",
    ReleaseEscrow: "Release Escrow",
  };

  return transactions.map((transaction) => ({
    ...transaction,
    accountNoFromUserFriendlyName:
      transaction.accountNoFrom == undefined
        ? transaction.accountNoFrom
        : entityNameMap[networkId][transaction.accountNoFrom.toLowerCase()] ||
          transaction.accountNoFrom,
    accountNoToUserFriendlyName:
      transaction.accountNoTo == undefined
        ? transaction.accountNoTo
        : entityNameMap[networkId][transaction.accountNoTo.toLowerCase()] ||
          transaction.accountNoTo,
    operationUserFriendlyName:
      transaction.operation == undefined
        ? transaction.operation
        : operationMap[transaction.operation] || transaction.operation,
  }));
}

export function getTimeStepForSmoothing(tDiff: number) {
  var tStep = 1000; // 1 second

  if (tDiff > 30 * 1000 && tDiff <= 5 * 60 * 1000) {
    tStep = 10 * 1000; // 10 seconds
  } else if (tDiff <= 15 * 60 * 1000) {
    tStep = 30 * 1000; // 30 seconds
  } else if (tDiff <= 30 * 60 * 1000) {
    tStep = 1 * 60 * 1000; // 1 minute
  } else if (tDiff <= 150 * 60 * 1000) {
    tStep = 5 * 60 * 1000; // 5 minutes
  } else if (tDiff <= 5 * 60 * 60 * 1000) {
    tStep = 10 * 60 * 1000; // 10 minutes
  } else if (tDiff <= 15 * 60 * 60 * 1000) {
    tStep = 30 * 60 * 1000; // 30 minutes
  } else if (tDiff <= 2 * 24 * 60 * 60 * 1000) {
    tStep = 1 * 60 * 60 * 1000; // 1 hour
  } else if (tDiff <= 7 * 24 * 60 * 60 * 1000) {
    tStep = 4 * 60 * 60 * 1000; // 4 hours
  } else if (tDiff <= 31 * 24 * 60 * 60 * 1000) {
    tStep = 24 * 60 * 60 * 1000; // 1 day
  } else if (tDiff <= 26 * 7 * 24 * 60 * 60 * 1000) {
    tStep = 7 * 24 * 60 * 60 * 1000; // 7 days
  } else if (tDiff <= 2 * 365 * 24 * 60 * 60 * 1000) {
    tStep = 30 * 24 * 60 * 60 * 1000; // 30 days
  } else if (tDiff <= 10 * 365 * 24 * 60 * 60 * 1000) {
    tStep = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months
  } else if (tDiff <= 30 * 365 * 24 * 60 * 60 * 1000) {
    tStep = 365 * 24 * 60 * 60 * 1000; // 1 year
  } else {
    tStep = 10 * 365 * 24 * 60 * 60 * 1000; // 10 years
  }

  return tStep;
}

export function getSmoothedTimeSeries(
  data: Array<any>,
  tFrom: Dayjs,
  tTo: Dayjs,
  amountAtTTo: number,
) {
  var times = data.map((datum) => dayjs(datum.Timestamp).local()).reverse();
  var amounts = data.map((datum) => datum.Amount).reverse();

  var tDiff = tTo.diff(tFrom);

  const tStep = getTimeStepForSmoothing(tDiff);

  var aggregatedTimes = [];
  var aggregatedAmounts = [];

  var t = tFrom;
  var i = 0;

  var aggregatedAmount;
  if (amounts.length === 0) {
    aggregatedAmount = amountAtTTo;
  } else {
    aggregatedAmount = amounts[0];
  }

  while (t.isBefore(tTo)) {
    while (i < times.length && times[i].isBefore(t.add(tStep))) {
      aggregatedAmount = amounts[i];
      i += 1;
    }

    aggregatedAmounts.push(aggregatedAmount);
    aggregatedTimes.push(t.toDate());
    t = t.add(tStep);
  }

  return { times: aggregatedTimes, amounts: aggregatedAmounts };
}

export function getAggregatedTimeSeries(
  data: Array<any>,
  tFrom: Dayjs,
  tTo: Dayjs,
) {
  var times = data.map((datum) => dayjs(datum.Timestamp).local()).reverse();
  var amounts = data.map((datum) => datum.Amount).reverse();

  var tDiff = tTo.diff(tFrom);

  const tStep = getTimeStepForSmoothing(tDiff);

  var aggregatedTimes = [];
  var aggregatedAmounts = [];

  var t = tFrom;
  var i = 0;

  while (t.isBefore(tTo)) {
    var aggregatedAmount = 0;
    while (i < times.length && times[i].isBefore(t.add(tStep))) {
      aggregatedAmount += amounts[i];
      i += 1;
    }

    aggregatedAmounts.push(aggregatedAmount);
    aggregatedTimes.push(t.toDate());
    t = t.add(tStep);
  }

  return { times: aggregatedTimes, amounts: aggregatedAmounts };
}
