import { TransactionHistoryTransactionsOperationEnum } from "../generated-client";
import { TransactionsWithUserFriendlyNames } from "../utils/helpers";
import { bridgeAddress } from "../components/Environment";

// Common formatting for transaction tables

/**
 * This string is displayed as a placeholder for undisplayable values
 */
export const EMPTY_VALUE = "--";

/**
 * Returns a string containing the user friendly operation name (if available),
 * and if applicable, the "Confirmed" status
 */
export function formattedOperation(
  transaction: TransactionsWithUserFriendlyNames,
): string {
  const operation =
    transaction.operationUserFriendlyName || transaction.operation;
  const status =
    transaction.status === "Confirmed" ? "" : ` (${transaction.status})`;
  return `${operation}${status}`;
}

/**
 * Returns the signed transaction amount or a placeholder.
 */
export function formattedAmount(
  transaction: TransactionsWithUserFriendlyNames,
): string {
  if (!transaction.amount || !transaction.operation) {
    return EMPTY_VALUE;
  }
  if (
    [
      TransactionHistoryTransactionsOperationEnum.ReleaseEscrow,
      TransactionHistoryTransactionsOperationEnum.Burn,
    ].includes(transaction.operation)
  ) {
    return String(-1 * transaction.amount);
  }
  return String(transaction.amount);
}

/**
 * Note: This needs to work for both UTXO-based ("NorgesBank") and account-based ("0x...") transactions
 * For Fabric txs, `transaction.ownAccountNo` contains the UTXO name "Bridge"
 * For Besu txs, the field is null
 * TODO: Provide the bridge besu address via the backend
 */
export function getCounterparty(
  transaction: TransactionsWithUserFriendlyNames,
): string {
  if (
    transaction.operation === TransactionHistoryTransactionsOperationEnum.Burn
  ) {
    return EMPTY_VALUE;
  }
  if (transaction.accountNoFrom === transaction.ownAccountNo) {
    return (
      transaction.accountNoToUserFriendlyName ||
      transaction.accountNoTo ||
      EMPTY_VALUE
    );
  }
  if (transaction.accountNoTo === transaction.ownAccountNo) {
    return (
      transaction.accountNoFromUserFriendlyName ||
      transaction.accountNoFrom ||
      EMPTY_VALUE
    );
  }
  if (
    transaction.accountNoFrom?.toLowerCase() === bridgeAddress?.toLowerCase()
  ) {
    return transaction.accountNoToUserFriendlyName || EMPTY_VALUE;
  }
  if (transaction.accountNoTo?.toLowerCase() === bridgeAddress?.toLowerCase()) {
    return transaction.accountNoFromUserFriendlyName || EMPTY_VALUE;
  }
  return EMPTY_VALUE;
}
